
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {

  //STAGING MVP
  baseUrl = 'https://hubadminapi.malbro.bigcityvoucher.co.in/v1'; 
  
  //prod

   //baseUrl ='https://hubadminapi.redemptionportalrbppl.in/v1'



  login = this.baseUrl + "/hubadmin/log-in";
  logout = this.baseUrl + "/hubadmin/logout";

  //company
  getCompanylist = this.baseUrl + "/hubadmin/list-company";
  addCompany = this.baseUrl + "/hubadmin/add-company";
  editCompany = this.baseUrl + "/hubadmin/edit-company";
  deleteCompany = this.baseUrl + "/hubadmin/deactivate-company";
  generateId = this.baseUrl + "/hubadmin/generate-id";

  orderDetails = this.baseUrl + '/client/getordercumulative'


  //programns

  addPrograms = this.baseUrl + "/hubadmin/add-programs";
  editPrograms = this.baseUrl + "/hubadmin/edit-programs";
  listPrograms = this.baseUrl + "/hubadmin/list-programs"
  deactivateProgram = this.baseUrl + "/hubadmin/deactivate-programs"

  // Product Category

  addCategory = this.baseUrl + "/hubadmin/add-product-cats"
  editCategory = this.baseUrl + "/hubadmin/edit-product-cats"
  listCategory = this.baseUrl + "/hubadmin/list-product-cats"
  deactivateCategory = this.baseUrl + "/hubadmin/deactivate-product-cats"

  //variation-masters 
  addVariationMas = this.baseUrl + "/hubadmin/add-variation-mas"
  listVariationMas = this.baseUrl + "/hubadmin/list-variation-mas"

  // variation
  addVariation = this.baseUrl + "/hubadmin/add-variation"
  listVariation = this.baseUrl + "/hubadmin/list-variation"

  // Products
  uploadProductFile = this.baseUrl + "/hubadmin/product-excel-upload"

  addProducts = this.baseUrl+"/hubadmin/add-product"

  // /Products-variation
  uploadProductVarFile = this.baseUrl + "/hubadmin/product-variation-upload"

  // ListOFProducts
  ListOfProducts = this.baseUrl + "/hubadmin/list-product"
  editProducts = this.baseUrl+"/hubadmin/edit-product"
  deleteDenomination = this.baseUrl+"/hubadmin/disable-denomination"

  //delivery partner
  getdelPartnerList = this.baseUrl + "/delivery-partner/get-partner?";

  //Redemption Panel
  getOrderList = this.baseUrl + "/redemption/order-listing?";
  getListProgram = this.baseUrl + "/hubadmin/list-programs";
  uploadEGVOrderStatus = this.baseUrl + "/redemption/order-offer-code-upload";
  uploadPhysiaclOrderStatus = this.baseUrl + "/redemption/order-statusupload";
  rejectDropdown = this.baseUrl + "/redemption/rejection-reason"; // rejected reason
  approveRejectOrder = this.baseUrl + "/redemption/approve-rejectby-bcp";
  deliveryPartner = this.baseUrl + "/redemption/delivery-partner";



  changeOrderStatusByForm = this.baseUrl + "/ops-panel/bulk-status-update?program_id=1";
  changeOrderStatusByeExcel = this.baseUrl + "/ops-panel/order-statusupload?program_id=1";
  getDownOrderExlData = this.baseUrl + "/ops-panel/get-orderexcel";
  getCancelReasonDropDown = this.baseUrl + "/ops-panel/get-order-cancelling-reason";

  getVoucherCodes = this.baseUrl + "/redemption/offercode"

  // Reverse Points
  ReversePoints = this.baseUrl + "/redemption/reversal-panel";
  RedemptionReversePoint = this.baseUrl + "/redemption/reverse-point"
  // physical reward
  downloadEgvRewards = this.baseUrl + "/redemption/egv-summary"
  downloadPhysicalRewardExcel = this.baseUrl + "/redemption/order-summary";
  orderStatus = this.baseUrl + "/redemption/readorderstatus";
  deliveryPatner = this.baseUrl + "/redemption/delivery-partner";
  storeStatus = this.baseUrl + "/redemption/setorderstatus";

  //Finance
  getProformaInvoice = this.baseUrl + "/client/getperforma?";
  approveSingleInvoice = this.baseUrl + "/client/setapprovalstatus?new_approval_status=2";
  downloadSingleInvoice = this.baseUrl + "/finance/proforma-download?";
  uploadInvoiceStatus = this.baseUrl + "/hubadmin/upload-invoice";
  getInvoiceList = this.baseUrl + "/hubadmin/list-invoice?";
  getServiceInvoiceList = this.baseUrl + "/finance/list-service-profoma?service_pi_status=2&invoice_flag=1"
  generatePI = this.baseUrl + "/finance/generate-proforma";
  verifyInvoice = this.baseUrl + "/hubadmin/verify-invoice?invoice_id=";
  updateInvoiceStatus = this.baseUrl + "/finance/update-invoice-status"
  downloadProofZip = this.baseUrl + "/client/download-invoice-proofs?invoice_id=";
  deliverOrders = this.baseUrl+'/finance/delivered-orders'
  servicesPI = this.baseUrl +'/finance/service-profoma'
  getServicePI = this.baseUrl + "/finance/list-service-profoma?service_pi_status=2&invoice_flag="
  uploadPayment = this.baseUrl + "/finance/upload-service-payment"
  uploadInvoiceService = this.baseUrl + "/finance/upload-invoice-service"
  //Agent Panel
  getAgentOrderList = this.baseUrl + "/report/customer-orders?";
  getSingleRedemptionInfo = this.baseUrl + "/redemption/offercode"
  getAgentOldOrdersList=this.baseUrl+"/report/old-redemptions"

  // Balance Check


  getListRewardCategory = this.baseUrl + "/hubadmin/list-product-cats"
  addRewardCategory = this.baseUrl + "/hubadmin/add-product-cats"
  editRewardCategory = this.baseUrl + "/hubadmin/edit-product-cats"

  // Rewards Products add

  getRewardsProductsList = this.baseUrl + "/hubadmin/list-product"
  getCategories = this.baseUrl + "/hubadmin/list-product-cats"
  uploadBulkProducts = this.baseUrl + "/hubadmin/product-excel-upload"
  disableProduct = this.baseUrl + "/hubadmin/active-deactive"
  editProduct = this.baseUrl + "/hubadmin/edit-product"


  //reward category
  getRewardCategories =
    this.baseUrl + "rewards/get-reward-category";
  addRewardCategories = this.baseUrl + "rewards/create-reward-category";
  editRewardCategories = this.baseUrl + "rewards/update-reward-category";
  deleteRewardCategories = this.baseUrl + "rewards/delete-reward-category";
  rewardHistory = this.baseUrl + "rewards/get-reward-orders?program_id=1";
  getRewardProducts =
    this.baseUrl + "rewards/get-reward-products";
  getRewardProductbyId =
    this.baseUrl + "rewards/get-reward-products?program_id=1&id=";
  createRewardProducts = this.baseUrl + "rewards/create-reward-products";
  updateRewardProducts = this.baseUrl + "rewards/update-reward-products";
  deleteRewardProducts = this.baseUrl + "rewards/delete-reward-products";
  getRewardSubCategory =
    this.baseUrl +
    "/rewards/get-reward-sub-categories?program_id=1&reward_category_id=";
  getRewardsBrands =
    this.baseUrl +
    "/rewards/get-reward-category-brands?program_id=1&reward_category_id=";
  
  getRoles = this.baseUrl + "user-role/get-role";
  downloadUserExcel = this.baseUrl + "users/download-user-excel?program_id=1";
  uploadMultiRewards = this.baseUrl + "rewards/create-reward-products-excel";
  getReardProductsWithPagination = this.baseUrl + 'rewards/get-reward-products-filter?';

  //reward sub category
  getRewardSubCategories =
    this.baseUrl + "rewards/get-reward-subcategory";
  addRewardSubCategories = this.baseUrl + "rewards/create-reward-subcategory";
  editRewardSubCategories =
    this.baseUrl + "rewards/update-reward-subcategory";
  deleteRewardSubCategories =
    this.baseUrl + "rewards/delete-reward-subcategory";
  //Reward Type
  getRewardType = this.baseUrl + "reward-type/get-reward-types";
  addRewardType = this.baseUrl + "reward-type/create-reward-type?id=1";
  editRewardType = this.baseUrl + "reward-type/update-reward-type?id=";
  deleteRewardType = this.baseUrl + "reward-type/update-reward-type?id=";

  //campaign
  getAllCampaign = this.baseUrl + "campaign/get-campaigns";
  addCampaign = this.baseUrl + "campaign/create-campaign";
  editCampaign = this.baseUrl + "campaign/update-campaign";
  deleteCampaign = this.baseUrl + "campaign/delete-campaign";
  //* GEOGRAPHY *//
  getAllGeographies = this.baseUrl + "geographical/get-geographs";
  addGeography = this.baseUrl + "geographical/create-geography";
  editGeography = this.baseUrl + "geographical/update-geography?id=";
  //ecommerce
  getProductsList = this.baseUrl + "product/listallproducts?program_id=1";
  getProductById =
    this.baseUrl + "product/editproduct?program_id=1&product_id=";
  getProductDelete = this.baseUrl + "product/deleteproduct";
  addToProductList = this.baseUrl + "product/addnewproduts";
  getProductCategories =
    this.baseUrl + "ecommerce/get-ecommerce-categories?program_id=1";
  updateProduct = this.baseUrl + "product/updateproduct";
  createGeography = this.baseUrl + "geographical/create-geography";
  getProductVarient = this.baseUrl + "product/get-product-variants";
  getProductVarientValues =
    this.baseUrl + "product/get-product-variant-values?product_variant_id=";
  createOfferDetails = this.baseUrl + "ecommerce/create-offer-details";
  getOfferDetails =
    this.baseUrl + "ecommerce/get-offer-details?program_id=1&offer_id=";
  getEcommerceSubCategories =
    this.baseUrl +
    "/ecommerce/get-ecommerce-sub-categories?program_id=1&category_id=";
  //****carton Insert****//
  getCartonList = this.baseUrl + "tertiary-programs/get-cartons?program_id=1";

  getCampaignsList = this.baseUrl + "carton-insert/campaigns-list";
  getProductBrand = this.baseUrl + "carton-insert/get-products?program_id=1";
  createCartonCampaign = this.baseUrl + "carton-insert/create-carton-campaign";
  CartonCampaignDetail = this.baseUrl + "carton-insert/get-campaigns-details";
  updateCartonCapmpaign = this.baseUrl + "carton-insert/update-carton-campaign";
  generateQRCarton = this.baseUrl + "carton-insert/qr-code-generation-api";
  cartonQrlist = this.baseUrl + "carton-insert/qr-code-list";
  cartonQrDownload = this.baseUrl + "carton-insert/download-qr-codes";
  cartonAssignUser = this.baseUrl + "carton-insert/whitelist-users";
  uploadUserByExl = this.baseUrl + "carton-insert/upload-whitelist-users";
  CartonWhiteList = this.baseUrl + "carton-insert/get-whitelist-users";
  pointConfigurationUpload = this.baseUrl + "carton-insert/points-structure";
  pointConfigurationList = this.baseUrl + "carton-insert/get-points-structure";
  cartonUploadTarget = this.baseUrl + "carton-insert/upload-targets";
  CartonTargetList = this.baseUrl + "carton-insert/get-targets-list";
  specialOcceranceList = this.baseUrl + "carton-insert/get-special-points-structure";
  specialOcceranceUpload = this.baseUrl + "carton-insert/special-points-structure";

  //Channels
  configGetChannel = this.baseUrl + "channels/get-channel?program_id=1";
  configAddChannel = this.baseUrl + "channels/create-channel";
  configEditChannel = this.baseUrl + "channels/update-channel";
  configDeleteChannel = this.baseUrl + "channels/delete-channel";
  // Languages
  configGetLanguages = this.baseUrl + "language/get-languages";
  configAddLanguage = this.baseUrl + "language/create-language";
  configEditLanguage = this.baseUrl + "language/update-language";
  configDeleteLanguage = this.baseUrl + "language/delete-language";
  // Frequency
  configGetFrequency = this.baseUrl + "frequency/get-frequency";
  configAddFrequency = this.baseUrl + "frequency/create-frequency";
  configEditFrequency = this.baseUrl + "frequency/update-frequency";
  // Units
  configGetUnit = this.baseUrl + "units/get-units";
  configAddUnit = this.baseUrl + "units/create-unit";
  configEditUnit = this.baseUrl + "units/update-unit?id=";

  //states
  configGetStates = this.baseUrl + "state/get-state?program_id=1";
  configAddState = this.baseUrl + "state/create-state";
  configEditState = this.baseUrl + "state/update-state";
  configDeleteState = this.baseUrl + "state/delete-state";
  importExcelState = this.baseUrl + "state/import-state";
  //Cities
  configGetCities = this.baseUrl + "city/get-city";
  configAddCity = this.baseUrl + "city/create-city";
  configEditCity = this.baseUrl + "city/update-city";
  configDeleteCity = this.baseUrl + "city/delete-city";
  importExcelCity = this.baseUrl + "city/import-city";

  //Bannres
  bannersList = this.baseUrl + "banner/get-banner?program_id=1";
  createBanner = this.baseUrl + "banner/create-banner?";
  updateBanner = this.baseUrl + "banner/update-banner?";
  deleteBanner = this.baseUrl + "banner/delete-banner?";

  // Region
  configGetRegion = this.baseUrl + "region/get-region?program_id=1";
  configAddRegion = this.baseUrl + "region/create-region";
  configUpdateRegion = this.baseUrl + "region/update-region";
  configDeleteRegion = this.baseUrl + "region/delete-region";
  importExcelRegion = this.baseUrl + "region/import-region";
  userPointSummary = this.baseUrl + 'staff/get-user-points?id='

  //Schemes
  getSchemes = this.baseUrl + "scheme/get-scheme";
  addSchemes = this.baseUrl + "scheme/create-scheme-data";
  getSchemeConfig = this.baseUrl + "scheme/get-scheme?id=";
  getSchemeProductCategory = this.baseUrl + "scheme/get-brand-category";
  extendScheme = this.baseUrl + 'scheme/update-scheme-date?id=';
  getSchemeConfigSettings = this.baseUrl + '';
  updateScheme = this.baseUrl + 'scheme/update-scheme?id=';
  downloadSchemeUserExcel = this.baseUrl + 'scheme/download-users?program_id=1'

  uploadSchemeUserExcel = this.baseUrl + "users/intermediate-user"
  getSpinWheels = this.baseUrl + 'spin-wheel/list-spin-config?id='



  /****/
  // targetUserFilter = 
  getTargetAchivementList = this.baseUrl + "loyalty-target/get-targets";
  updateTargetAchivement = this.baseUrl + "loyalty-target/update-target";
  addTargetAchivement = this.baseUrl + "loyalty-target/create-target";
  assignedLoyaltyTarget = this.baseUrl + "loyalty-target/get-loyalty-targets";
  createOffer = this.baseUrl + "ecommerce/create-offers";
  deleteOffer = this.baseUrl + "ecommerce/delete-offers";
  updateOffer = this.baseUrl + "ecommerce/update-offers";
  createWhitelist = this.baseUrl + "user-modules/create-user-module";
  getModule = this.baseUrl + "module/get-modules";
  getUserModules = this.baseUrl + "user-modules/get-user-modules";
  createLoyalty = this.baseUrl + "loyalty-target/add-target-point-structure";
  getAssignedUser = this.baseUrl + "loyalty-target/get-assigned-users";
  getLoyaltyTargets = this.baseUrl + "loyalty-target/get-loyalty-targets";
  getPointStructure = this.baseUrl + "points-structure/get-points-structure";
  updateLoyaltyTarget = this.baseUrl + "loyalty-target/update-loyalty-target";
  updatePointsTarget =
    this.baseUrl + "points-structure/update-points-structure";
  downloadTargetFile = this.baseUrl + 'loyalty-target/download-target-details';
  downloadAchievementExcel = this.baseUrl + 'loyalty-target/download-achievement-target?program_id=1';

  //Visibility /get-posm-config
  getVisibilityList =
    this.baseUrl + "visibility/get-posm-elements?program_id=1";
  addVisibility = this.baseUrl + "visibility/create-posm-elements";
  editVisibility = this.baseUrl + "visibility/update-posm-elements";
  deleteVisibility = this.baseUrl + "visibility/delete-posm-elements";
  saveVisibilityConfig = this.baseUrl + "visibility/create-posm-config";
  getVisibilityConfig =
    this.baseUrl + "visibility/get-posm-config?program_id=1";
  updateVisibilityConfig = this.baseUrl + "visibility/update-posm-config";
  getVisibilitySubmittedList =
    this.baseUrl +
    "/visibility/get-posm-audit-transactions?page=";
  visibilityApproveOrReject =
    this.baseUrl + "visibility/posm-audit-approve-reject";
  getPosmOnChannel = this.baseUrl + 'visibility/get-channel-posm-elements?program_id=1&channel_id=';
  getShelfConditions = this.baseUrl + 'visibility/get-shelves-cooler-purity?program_id=1'
  getShelfConditionsForSubmission = this.baseUrl + 'visibility/get-shelves-points?program_id=1&posm_master_config=';

  //Add Amount

  getAddAmountList = this.baseUrl + "/summary/listsummary";
  addAmount = this.baseUrl + "/summary/addamount";
  //reports
  redemptionReport = this.baseUrl + '/report/redemption-report'
  catalogueReport = this.baseUrl + '/report/sku-report'
  loginReport = this.baseUrl + '/report/login-report'
  cancellationReport = this.baseUrl + '/report/cancellation-report'
  pointBalanceReport = this.baseUrl + '/report/pointbalance-report'
  deductionReport = this.baseUrl + '/report/deduction-report'

  // Rewareds
  my_rewards_list = this.baseUrl + '/scratch-cards/scratch-card-headers';
  upload_my_rewards = this.baseUrl + '/scratch-cards/import-scratch-card';
  create_reward_brand = this.baseUrl + '/rewards/create-reward-brands';
  get_reward_brand = this.baseUrl + '/rewards/get-reward-brands';
  update_reward_brand = this.baseUrl + '/rewards/update-reward-brands';
  delete_reward_brand = this.baseUrl + '/rewards/delete-reward-brands';
  uploadOrderDetails = this.baseUrl + "/ecommerce/import-order-details";
  uploadRewardsCategories = this.baseUrl + "/rewards/import-reward-category?";
  uploadRewardsSubCategories = this.baseUrl + "/rewards/import-reward-sub-category?";
  uploadRewardBrands = this.baseUrl + "/rewards/import-reward-brands?";
  checker_maker_bonous_points = this.baseUrl + '/loyalty-target/get-bonous?';
  create_communication_matrix = this.baseUrl + '/communication-panel/create-scenario';
  //Instant-reward_rules
  getInstantRewardRules = this.baseUrl   + "/rewards/get-instant-reward-rules";
  instantRewardRules = this.baseUrl + "/rewards/instant-reward-rules"
  // Instant Voucher Upload
  uploadInstantVoucher = this.baseUrl + "/rewards/instant-voucher-upload";
  getInstantVoucherCodes = this.baseUrl + "/rewards/list-instant-product";
  getInventoryDetails = this.baseUrl + "/summary/get-inventory";

  getAmozonWoohoBalance =this.baseUrl + '/common/check-balance?&secret_key=$2y$10$z0ihWtyW2HsEAh1VMNbGUOXKN1w9RJo8Vgwb6PLCsQLU.F2OcVU5W' 
}
export interface ApiConfigurationInterface { }